import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <Heading subtitle="Verantwortung für unsere Umwelt und Gesellschaft" mdxType="Heading">Nachhaltigkeit bei Ihrem Juwelier Münzer</Heading>
    <Wrapper width={1024} mdxType="Wrapper">
      <p>{`  Als ortsansässiger Juwelier und Familienunternehmen legen wir großen Wert auf gelebte Nachhaltigkeit.`}</p>
      <p>{`  Wir setzen uns aktiv dafür ein, dass unsere Branche einen positiven Einfluss auf die Umwelt und die Gesellschaft hat.
Wir sind uns bewusst, dass die Gewinnung von Edelmetallen und Edelsteinen mit ökologischen und sozialen
Herausforderungen verbunden ist, und wir möchten unseren Teil dazu beitragen, diese Herausforderungen anzugehen.`}</p>
      <p>{`  Wir beziehen unseren Schmuck und Uhren von vertrauenswürdigen Lieferanten und langjährigen Geschäftspartnern,
die strenge Standards in Bezug auf Arbeitsbedingungen, Menschenrechte und den Schutz der Umwelt einhalten. So
unterstützen wir die Förderung von fair gehandelten Edelmetallen, Edelsteinen und Perlen.`}</p>
      <p>{`  Der Schutz unserer Umwelt liegt uns sehr am Herzen. Daher entsorgen wir Batterien fachgerecht und achten bewusst
darauf, dass unsere Verpackungen nachhaltig und umweltfreundlich gestaltet sind, ohne dabei auf Luxus,
Qualität und optisch Anmutung zu verzichten.`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Trauringe hergestellt in Deutschland`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Trauringe aus recyceltem Gold`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Schmuck hergestellt in Deutschland`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Etuis aus recyceltem Material`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Tüten aus einem sozialen Projekt`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Recycling von Batterien`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Wir beziehen Ökostrom`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Wir haben einen hohen Grand an Digitalisierung`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Wir kaufen Altgold zur Resourcenschonung an`}</p>
          <p parentName="li">{`Ihr nachhaltig orientierter Juwelier Münzer in Darmstadt`}</p>
          <hr parentName="li"></hr>
        </li>
      </ul>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      